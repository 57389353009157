<template>
  <div
    v-if="isFreemiumUser"
    class="StreamsSidebarUpgradeNow has-background-info p-a-m"
  >
    <heading
      size="5"
      color="black"
    >
      {{ $t('components.streams.streams_sidebar_upgrade_now.header') }}
    </heading>
    <div class="is-size-6">
      <p>
        {{ $t('components.streams.streams_sidebar_upgrade_now.body') }}
      </p>
      <router-link
        :to="{ name: 'cx.monitor' }"
        class="has-text-weight-bold has-text-primary"
      >
        {{ $t('components.streams.streams_sidebar_upgrade_now.click_to_upgrade') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * Renders a small upgrade now box, right above the StreamsSidebar.
 * Used on the App.vue
 * @module StreamsSidebarUpgradeNow
 */
export default {
  name: 'StreamsSidebarUpgradeNow',
  computed: {
    ...mapGetters(['isFreemiumUser'])
  }
}
</script>
